<template>
    <div>
        <v-text-field v-model="category"
                    label="Category"
                    chips
                    :prepend-icon="prepend"
                    hint="What Category does this belong to?"
                    :persistent-hint="hint"
                    :error-messages="categoryErrors"
                    @input="$v.category.$touch(), updateParent()"
                    @blur="$v.category.$touch()"
                    @click="showList = !showList">
        </v-text-field>

        <CategoryList v-if="showList"
                      v-on:categoryShow="showList = $event"  
                      v-on:selectedCategory="updateCategory($event)"></CategoryList>
    </div>
</template>

<script>
    import { validationMixin } from 'vuelidate'
    import { required } from 'vuelidate/lib/validators'

    import { isNullEmptyOrUndefined } from '../../javascript/globalFunctions'

    export default {
        mixins: [validationMixin],
        components: {
            CategoryList: () => import('./categoryList.vue')
        },
        props: ['sentCategory', 'options'],
        data() {
            return {
                category: '',
                showList: false,
                prepend: "fa-tag",
                hint: true,
            }
        },
        computed: {
            categoryErrors() {
                const errors = []
                if (!this.$v.category.$dirty) return errors
                !this.$v.category.required && errors.push('Category is required')
                return errors
            },
        },
        methods: {
            checkSentCategory: function () {
                if (!isNullEmptyOrUndefined(this.sentCategory)) {
                    this.category = this.sentCategory
                }
            },
            updateParent: function () {
                this.$emit('category', this.category)
            },
            updateCategory: function (category) {
                this.category = category

                this.updateParent()
            },
            addOptions: function () {
                if (!isNullEmptyOrUndefined(this.options)) {
                    this.prepend = this.options.prepend
                    this.hint = this.options.hint
                }
            },
        },
        beforeMount() {
            this.checkSentCategory()
            this.addOptions()
        },
        validations: {
            category: {
                required
            },
        }

    }
</script>